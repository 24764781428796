const APP_DATA = {
    name:'healthy',
    env:'production',
    platform_module: null, // null (genera un bypass en login para cualquier usuario en nómina) // comprueba en routes/index.js y en Login
    db_url:{
        production: 'https://fs-healthy.firebaseio.com',
        develop: 'https://bromatologia-5c2c2-default-rtdb.firebaseio.com'
    },
    db_config:{
        production: {
            apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
            authDomain: "cantina-89c6a.firebaseapp.com",
            databaseURL: "https://fs-healthy.firebaseio.com",
            projectId: "cantina-89c6a",
            storageBucket: "cantina-89c6a.appspot.com",
            messagingSenderId: "353062217145",
            appId: "1:353062217145:web:8849a49a1ec52077"
        },
        develop: {
            apiKey: "AIzaSyAzUSOrp0d-MPdyZTfEebbkqGT4PXC0II0",
            authDomain: "bromatologia-5c2c2.firebaseapp.com",
            databaseURL: "https://bromatologia-5c2c2-default-rtdb.firebaseio.com",
            projectId: "bromatologia-5c2c2",
            storageBucket: "bromatologia-5c2c2.appspot.com",
            messagingSenderId: "823401463627",
            appId: "1:823401463627:web:4aa81e96f1960c7746f2ce"
        }
    },
    
    url: 'https://healthy.foodservice.com.ar',
    description: '',

    api(){
        return this.db_url[this.env]
    }
}

export default APP_DATA;