import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
        children: [
            {
                path: "",
                name: "login",
                component: () => import(/* webpackChunkName: "LoginView" */ "@/views/LoginView.vue"),
            },
        ],
    },
    {
        path: "/home",
        component: () => import(/* webpackChunkName: "AppLayout" */ "@/layouts/AppLayout.vue"),
        children: [
            {
                path: "",
                name: "home",
                component: () => import(/* webpackChunkName: "HomeView" */ "@/views/HomeView.vue"),
            },
            {
                path: "/noticias",
                name: "posts",
                component: () => import(/* webpackChunkName: "PostsView" */ "@/views/PostsView.vue"),
            },
            {
                path: "/noticia/:id",
                name: "post",
                component: () => import(/* webpackChunkName: "PostView" */ "@/views/PostView.vue"),
            },
            {
                path: "/datos",
                name: "data",
                component: () => import(/* webpackChunkName: "DataView" */ "@/views/DataView.vue"),
            },
            {
                path: "/perfil",
                name: "profile",
                component: () => import(/* webpackChunkName: "ProfileView" */ "@/views/ProfileView.vue"),
            },
            {
                path: "/consumos",
                name: "ConsumosVista",
                component: () => import(/* webpackChunkName: "ConsumptionsView" */ "@/views/ConsumosVista.vue"),
            },
            {
                path: "/plato/:id",
                name: "dish",
                component: () => import(/* webpackChunkName: "DishView" */ "@/views/DishView.vue"),
            },
            {
                path: "/tag/:id",
                name: "tag",
                component: () => import(/* webpackChunkName: "TagInfoView" */ "@/views/TagInfoView.vue"),
            },
            {
                path: "/menu",
                name: "menu",
                component: () => import(/* webpackChunkName: "WeeklyMenuView" */ "@/views/WeeklyMenuView.vue"),
            },
        ],
    },
    {
        path: "/registro",
        component: () => import(/* webpackChunkName: "LoginLayout" */ "@/layouts/LoginLayout.vue"),
        children: [
            {
                path: "",
                name: "register",
                component: () => import(/* webpackChunkName: "RegisterView" */ "@/views/RegisterView.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (!localStorage.getItem("verified_operation")) {
        let url = new URL(window.location);
        if (url.searchParams.has("c")) {
            fetch(`https://salida.foodservice.com.ar/api/check/operation/${url.searchParams.get("c")}`)
                .then((r) => r.json())
                .then((data) => {
                    if (data != null && Object.keys(data).length) localStorage.setItem("verified_operation", url.searchParams.get("c"));
                });
        }
    }

    if (to.name == "dish" || to.name == "register" || to.name == "tag") next();

    if (to.name !== "login" && !localStorage.getItem("user")) {
        next({ name: "login" });
    } else {
        next();
    }
});

// function isAuthenticated() {
//     return localStorage.getItem("user") != null;
// }

export default router;
